<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Lista kategorii'">
          <template v-slot:preview>
            <div>
              <ul class="list-unstyled">
                <b-media
                  tag="li"
                  class="my-4"
                  v-for="category in prizeCategories"
                  :key="category.id"
                >
                  <template v-slot:aside>
                    <b-img
                      width="64"
                      height="64"
                      alt="placeholder"
                      :src="getImageUrl(category.image)"
                      @click="getPrizesByCategory(category.id)"
                      class="pointer"
                    ></b-img>
                  </template>
                  <h5 class="mt-0 mb-1">
                    <a
                      href="#"
                      @click.prevent="getPrizesByCategory(category.id)"
                      >{{ category.name }}</a
                    >
                  </h5>
                  <p class="mb-0">
                    {{ category.description }}
                  </p>
                </b-media>
              </ul>
            </div>
          </template>
        </KTCodePreview>
        <div>
          <KTCodePreview v-bind:title="'Lista nagród w wybranej kategorii'">
            <template v-slot:preview>
              <Prizes :prizes="prizes" :category="categoryName" />
            </template>
          </KTCodePreview>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTCodePreview from "@/view/content/CodePreview.vue";
import ApiService from "@/core/services/api.service";
import Prizes from "@/view/pages/customer/prizes/Prizes.vue";
import Commons from "@/core/services/commons.service";

export default {
  components: {
    KTCodePreview,
    Prizes
  },
  data() {
    return {
      prizeCategories: [],
      prizes: {},
      categoryName: ""
    };
  },
  methods: {
    getImageUrl: Commons.getImageUrl,
    getPrizesByCategory(id) {
      this.categoryName = (
        this.prizeCategories.find(c => c.id === id) || { name: "" }
      ).name;
      ApiService.get("/prizes/" + id).then(response => {
        this.prizes = response.data;
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Katalog Nagród" },
      { title: "" }
    ]);

    ApiService.get("/prize/categories").then(response => {
      this.prizeCategories = response.data;
    });
  }
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
