<template>
  <div>
    <b-row>
      <b-col xs="12" sm="4" v-for="prize in prizes" :key="prize.id">
        <b-card
          :title="prize.erpPrize.code"
          :img-src="getFirsImageUrl(prize.images)"
          img-alt="Image"
          img-top
          tag="article"
          class="mb-2"
        >
          <b-card-text>
            {{ prize.erpPrize.name }}
          </b-card-text>

          <b-row no-gutters>
            <b-col md="12" class="text-right">
              <h2>
                <b-badge block variant="warning"
                  >{{ numberFormat(prize.erpPrize.cost) }} pkt</b-badge
                >
              </h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-button
                href="#"
                block
                variant="primary"
                @click="showPrize(prize.id)"
                >Zobacz</b-button
              >
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <template>
      <b-modal
        id="prizeDetail"
        size="lg"
        scrollable
        ref="prizeDetail"
        :title="category"
      >
        <b-container>
          <Prize :prize="selected" @close-self="closePrize"></Prize>
        </b-container>
        <template #modal-footer><span></span></template>
      </b-modal>
    </template>
  </div>
</template>

<script>
import Prize from "@/view/pages/customer/prizes/Prize";
import Commons from "@/core/services/commons.service";

export default {
  name: "Prizes",
  components: {
    Prize
  },
  data() {
    return {
      selected: {}
    };
  },
  props: {
    prizes: {},
    category: String
  },
  methods: {
    getImageUrl: Commons.getImageUrl,
    getFirsImageUrl(images) {
      if (images && images.length > 0) {
        return this.getImageUrl(images[0]);
      } else {
        return "https://picsum.photos/400/400/?image=20";
      }
    },
    showPrize(id) {
      this.selected = this.prizes.find(p => p.id === id);
      this.$refs.prizeDetail.show("prizeDetail");
    },
    closePrize() {
      this.$refs.prizeDetail.hide("prizeDetail");
    },
    numberFormat(value) {
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    }
  }
};
</script>
