<template>
  <b-card no-body class="overflow-hidden">
    <b-row no-gutters>
      <b-col md="6">
        <b-card-img
          :src="getFirsImageUrl(prize.images)"
          class="rounded-0"
        ></b-card-img>
      </b-col>
      <b-col md="6">
        <b-card-body
          :title="prize.erpPrize.code"
          :sub-title="prize.erpPrize.name"
        >
          <b-card-text>
            <b-card>
              <template #header>
                <b-row>
                  <b-col cols="7">
                    <b-badge variant="warning">
                      {{ numberFormat(prize.erpPrize.cost) }} pkt
                    </b-badge>
                  </b-col>
                  <b-col cols="5">
                    <a
                      href="#"
                      @click="decAmount()"
                      class="btn btn-xs btn-light-success btn-icon mr-2"
                    >
                      <i class="ki ki-minus icon-xs"></i>
                    </a>
                    <span
                      class="font-weight-bold mr-2 text-dark-75 font-size-3"
                    >
                      {{ amount }}
                    </span>

                    <a
                      href="#"
                      @click="incAmount()"
                      class="btn btn-xs btn-light-success btn-icon"
                    >
                      <i class="ki ki-plus icon-xs"></i>
                    </a>
                  </b-col>
                </b-row>
              </template>
              <b-card-text>
                {{ prize.description }}
              </b-card-text>
              <template #footer>
                <div class="text-center">
                  <b-button block variant="success" @click="order()">
                    Zamów
                  </b-button>
                  <b-button block variant="danger" @click="hide">
                    Zamknij
                  </b-button>
                </div>
              </template>
            </b-card>
          </b-card-text>
        </b-card-body>
      </b-col>
    </b-row>
    <b-card-footer>
      <b-container fluid class="p-4 bg-dark" v-if="images.length">
        <b-row>
          <b-col v-for="img in images" :key="img">
            <b-img thumbnail fluid :src="getImageUrl(img)"></b-img>
          </b-col>
        </b-row>
      </b-container>
    </b-card-footer>
  </b-card>
</template>

<script>
import Commons from "@/core/services/commons.service";
import { ADD_TO_CART } from "@/core/services/store/cart.module";

export default {
  name: "Prize",
  props: {
    prize: {
      images: []
    }
  },
  data() {
    return {
      amount: 1
    };
  },
  emits: ["close-self"],
  computed: {
    images() {
      if (this.prize.images) {
        const [, ...rest] = this.prize.images;
        return rest;
      } else {
        return [];
      }
    }
  },
  methods: {
    getImageUrl: Commons.getImageUrl,
    getFirsImageUrl(images) {
      if (images && images.length > 0) {
        return this.getImageUrl(images[0]);
      } else {
        return "https://picsum.photos/400/400/?image=20";
      }
    },
    decAmount() {
      const newAmount = this.amount - 1;
      this.amount = newAmount <= 0 ? 1 : newAmount;
    },
    incAmount() {
      this.amount = this.amount + 1;
    },
    order() {
      this.$store.dispatch(ADD_TO_CART, {
        prize: this.prize,
        quantity: this.amount
      });
      this.$emit("close-self");
    },
    hide() {
      this.$emit("close-self");
    },
    numberFormat(value) {
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    }
  }
};
</script>
